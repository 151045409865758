import { startCase } from 'lodash/fp'

/**
 * Converts a given string to title case.
 * Title case means that the first letter of each word is capitalized.
 *
 * @param input - The string to be converted to title case. Defaults to an empty string.
 * @returns The converted string in title case.
 */
export const toTitleCase = (input: string = '') =>
  startCase(input.toLowerCase())

/**
 * Rounds a given number to two decimal places.
 *
 * @param number - The number to be rounded.
 * @returns The number rounded to two decimal places.
 */
export const roundToTwoDecimals = (number: number) =>
  typeof number === 'number' ? Math.round(number * 100) / 100 : null

/**
 * Extracts the file extension from a given string.
 *
 * @param input - The input string from which to extract the file extension. Defaults to an empty string.
 * @returns The file extension if present, otherwise undefined.
 */
export const getFileExtension = (input: string = '') => input.split('.').pop()

/**

 * Formats discount value based on the given type.
 *
 * @param type - The input string from which we decide the symbol
 * @param value - The discount value which needs to be formatted
 * @returns The formatted disocunt value.
 */
export const formatDiscountLabel = (type: string, value: number) => {
  if (!type) return
  if (type === 'PercentageOff') return ` ${value}%`
  else if (type === 'FixedPrice' || type === 'DollarOff') return `$${value}`
}

/**
 * Validates whether a given variable is an array or not
 *
 * @param arr - The array to be checked
 * @returns boolean value
 */
export const isArrayEmpty = (arr: any): boolean =>
  !Array.isArray(arr) || arr.length === 0
