import { Divider, Grid, Heading } from '@enterprise-ui/canvas-ui-react'

import CategoryReturnRateGraph from './CategoryReturnRateGraph'
import { StyledReturnRateCardContainer } from 'v2/components/feature/ReturnInsights/styles'
import ReportDownload from 'v2/components/feature/ReturnInsights/ReportDownload'

const CategoryReturnRate = () => {
  return (
    <StyledReturnRateCardContainer className="hc-pa-normal">
      <Grid.Container justify="space-between">
        <Grid.Item>
          <Heading className="hc-txt-uppercase" size={4}>
            Category Return Rate
          </Heading>
          <p className="hc-clr-grey02 hc-fs-xs">
            Top 5 Categories and their return rates in the selected time frames
          </p>
        </Grid.Item>
        <Grid.Item>
          <ReportDownload
            returnPerformanceReportTypes={['CATEGORY_RETURN_RATE']}
            fileName="category-return-rate"
          />
        </Grid.Item>
      </Grid.Container>
      <Divider className="hc-mt-xs" />
      <Grid.Container className="hc-pa-normal">
        <Grid.Item xs={12}>
          <Grid.Container
            direction="column"
            align="flex-end"
            justify="flex-end"
          >
            <Grid.Item xs={12}>
              <CategoryReturnRateGraph />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
    </StyledReturnRateCardContainer>
  )
}

export default CategoryReturnRate
