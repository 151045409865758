import axios from 'axios'
import apiConfig from 'config/apiConfig'

export interface SellerInternalType {
  gmv_potential: string
}

export async function getSellerInternal(
  id: string,
): Promise<SellerInternalType | undefined> {
  try {
    const seller = await axios.get(`${apiConfig.sms}/sellers/${id}/internal`)
    return seller.data
  } catch (error) {
    return Promise.reject([])
  }
}
