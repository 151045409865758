import { ShippingMethod } from 'types/ShippingMethods'
import { Validation } from 'types/Validation'

export const closeAside = () =>
  ({
    type: 'CLOSE_ASIDE',
  }) as const

export const setPending = (pending: boolean) =>
  ({
    type: 'SET_PENDING',
    payload: {
      pending,
    },
  }) as const

export const setValidation = ({
  validation,
  isValid,
}: {
  validation: Validation
  isValid: boolean
}) =>
  ({
    type: 'SET_VALIDATION',
    payload: {
      validation,
      isValid,
    },
  }) as const

export const setShippingMethods = (shippingMethods: ShippingMethod[]) =>
  ({
    type: 'SET_SHIPPING_METHODS',
    payload: {
      shippingMethods,
    },
  }) as const

export const setTotalShippingMethods = (total: number) =>
  ({
    type: 'SET_TOTAL_SHIPPING_METHODS',
    payload: {
      total,
    },
  }) as const

export const updateOnInputChange = (name: string, value: Nullable<string>) =>
  ({
    type: 'UPDATE_ON_INPUT_CHANGE',
    payload: {
      name,
      value,
    },
  }) as const

export const addNewShippingMethod = () =>
  ({
    type: 'ADD_NEW_SHIPPING_METHOD',
  }) as const

export const editShippingMethod = (shippingMethod: ShippingMethod) =>
  ({
    type: 'EDIT_SHIPPING_METHOD',
    payload: {
      shippingMethod,
    },
  }) as const

export const deleteShippingMethod = (shippingMethod: ShippingMethod) =>
  ({
    type: 'REMOVE_SHIPPING_METHOD',
    payload: {
      shippingMethod,
    },
  }) as const

export type Action = ReturnType<
  | typeof closeAside
  | typeof setPending
  | typeof setValidation
  | typeof setShippingMethods
  | typeof setTotalShippingMethods
  | typeof updateOnInputChange
  | typeof addNewShippingMethod
  | typeof editShippingMethod
  | typeof deleteShippingMethod
>

interface State {
  isAsideOpen: boolean
  pending: boolean
  total: number
  shippingMethods: ShippingMethod[]
  shippingMethod: Nullable<ShippingMethod>
  method: string
  carrier: string
  scac: string
  csc: string
  mode: string
  routing: string
  validation: Validation
  isValid: boolean
  isApprovedForPartners: boolean
}

export const initialReducerState: State = {
  isAsideOpen: false,
  pending: false,
  total: 0,
  shippingMethods: [],
  shippingMethod: null,
  method: '',
  carrier: '',
  scac: '',
  csc: '',
  mode: '',
  routing: '',
  validation: {},
  isValid: false,
  isApprovedForPartners: false,
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'CLOSE_ASIDE': {
      return {
        ...state,
        mode: '',
        routing: '',
        isApprovedForPartners: false,
        isAsideOpen: false,
      }
    }
    case 'SET_PENDING': {
      const { payload } = action
      const newState = {
        ...state,
        pending: payload.pending,
      }

      return newState
    }

    case 'SET_VALIDATION': {
      const { payload } = action
      const newState = {
        ...state,
        validation: payload.validation,
        isValid: payload.isValid,
      }

      return newState
    }

    case 'SET_SHIPPING_METHODS': {
      const { payload } = action
      const newState = {
        ...state,
        shippingMethods: payload.shippingMethods,
      }
      return newState
    }

    case 'SET_TOTAL_SHIPPING_METHODS': {
      const { payload } = action
      const newState = {
        ...state,
        total: payload.total,
      }
      return newState
    }

    case 'UPDATE_ON_INPUT_CHANGE': {
      const newState = {
        ...state,
        [action.payload.name]: action.payload.value,
      }

      return newState
    }

    case 'ADD_NEW_SHIPPING_METHOD': {
      const newState = {
        ...state,
        isAsideOpen: true,
        shippingMethod: initialReducerState.shippingMethod,
        method: initialReducerState.method,
        carrier: initialReducerState.carrier,
        scac: initialReducerState.scac,
        csc: initialReducerState.csc,
        mode: initialReducerState.mode,
        routing: initialReducerState.routing,
        isApprovedForPartners: initialReducerState.isApprovedForPartners,
      }

      return newState
    }

    case 'EDIT_SHIPPING_METHOD': {
      const { payload } = action
      const { shippingMethod } = payload
      const {
        shipping_method,
        carrier,
        scac,
        service_code,
        routing = '',
        mode = '',
        approved_for_partners = false,
      } = shippingMethod

      const newState = {
        ...state,
        isAsideOpen: true,
        shippingMethod,
        method: shipping_method,
        carrier,
        mode,
        scac,
        routing,
        isApprovedForPartners: approved_for_partners,
        csc: service_code,
        validation: {},
        isValid: true,
      }
      return newState
    }

    case 'REMOVE_SHIPPING_METHOD': {
      const { payload } = action
      const { shippingMethod } = payload
      const newState = {
        ...state,
        isAsideOpen: false,
        shippingMethod,
      }
      return newState
    }

    default:
      return state
  }
}

export default reducer
