import axios from 'axios'
import { saveAs } from 'file-saver'

import { getFileExtension } from 'v2/utils/helper'

interface DownloadReportParams {
  downloadUrl: string
  fileName: string
}

export const getDownloadReport = async ({
  downloadUrl,
  fileName,
}: DownloadReportParams) => {
  const type = 'application/octet-stream;charset=utf-8'
  const externsion = getFileExtension(downloadUrl)
  const name = `${fileName}.${externsion}`

  const { data } = await axios.get(downloadUrl, {
    headers: {
      Accept: 'application/octet-stream',
    },
    responseType: 'blob',
  })

  saveAs(new Blob([data], { type }), name)
}
