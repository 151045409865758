import axios from 'axios'
import apiConfig from 'config/apiConfig'
import { UpdatedPromotion } from 'types/Promotion'

export type PromotionDetails = {
  discount_type: string
  discount_value: number
  location_ids: number[]
}

export type MerchandiseHierarchy = {
  pyramid_id: number
  pyramid_name: string
  division_id?: number
  division_name?: string
}

export interface Promotion {
  promotionId: string
  umbrellaLabel?: string
  details: PromotionDetails[]
  startDate: string
  endDate: string
  merchandiseHierarchies: MerchandiseHierarchy[]
  allowedProductSubTypes?: {
    sub_type_id: string
    sub_type_name: string
    allowed_item_types_ids: string[]
  }[]
  promotionDescription?: string
  participationEndDate?: string
  promotionNotes?: string
}

interface Payload {
  params: {
    promotion_id?: string
  }
}

export const getPromotionById = async (
  payload: Payload,
  sellerId: string,
): Promise<UpdatedPromotion[]> => {
  const { data } = await axios.get(
    `${apiConfig.sellerPromotions}/sellers/${sellerId}/promotions`,
    payload,
  )

  return data
}
