import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
  Anchor,
  Drawer,
  Grid,
  Heading,
  Placeholder,
  Button,
} from '@enterprise-ui/canvas-ui-react'

import { isArrayEmpty } from 'v2/utils/helper'
import { currentSellerId, currentSellerVmmId } from 'store/selectors'
import { sellerExecuteCardQuery } from 'v2/query'
import { GREEN_FIELD_CARD_ID } from 'v2/constant/greenFieldCardID'
import { StyledHelpArticleGridContainer } from '../../styles'
import { getReasonsList, IQueAndLink } from './helper'

const headingText = 'Partner Help Articles'

const HelpArticle = () => {
  const vmmId = useSelector(currentSellerVmmId) ?? ''
  const sellerId = useSelector(currentSellerId) ?? ''
  const [articles, setArticles] = useState<IQueAndLink[]>([])
  const [isHelpArticleOpen, setIsHelpArticleOpen] = useState(false)

  const { data, isFetching, isError } = useQuery({
    queryFn: () =>
      sellerExecuteCardQuery({
        cardId: GREEN_FIELD_CARD_ID.SELLER_HELP_ARTICLE,
        sellerId,
        interval: 'last segment',
        vmmId,
      }),
    enabled: isHelpArticleOpen,
  })

  useEffect(() => {
    setArticles(getReasonsList(data))
  }, [data])

  const handleHelpArticle = () => {
    setIsHelpArticleOpen(true)
  }

  const getContent = () => {
    if (isFetching) {
      return (
        <Placeholder>
          <Grid.Container justify="center" spacing="dense" direction="column">
            {Array.from({ length: 5 }).map((_, index) => (
              <Grid.Item key={index} xs={12}>
                <Placeholder.Rect height="30px" />
              </Grid.Item>
            ))}
          </Grid.Container>
        </Placeholder>
      )
    }

    if (isError) {
      return (
        <div
          data-testid="error-state-text"
          className="hc-pa-expanded hc-ta-center"
        >
          <p>There was an error fetching the data</p>
        </div>
      )
    }

    if (isArrayEmpty(articles)) {
      return (
        <div
          data-testid="empty-state-text"
          className="hc-pa-expanded hc-ta-center"
        >
          <p>No articles available!</p>
        </div>
      )
    }

    return (
      <StyledHelpArticleGridContainer
        data-testid="articles-container"
        justify="space-between"
        direction="column"
      >
        <Grid.Item>
          {articles?.map(
            (ele: { question: string; link: string }, idx: number) => (
              <Anchor key={idx} href={ele?.link} target="_blank">
                <Heading key={idx} className="hc-fs-sm hc-pv-dense">
                  {ele?.question}
                </Heading>
              </Anchor>
            ),
          )}
        </Grid.Item>
      </StyledHelpArticleGridContainer>
    )
  }

  return (
    <>
      <Button size="expanded" type="submit" onClick={handleHelpArticle}>
        HELP ARTICLES
      </Button>

      <Drawer
        xs={3}
        isVisible={isHelpArticleOpen}
        headingText={headingText}
        onRequestClose={() => setIsHelpArticleOpen(false)}
        spacing="expanded"
      >
        {getContent()}
      </Drawer>
    </>
  )
}

export default HelpArticle
