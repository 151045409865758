import React from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import FullScreenDialogContainer from 'components/common/Dialog/FullScreenDialogContainer'
import useTable from 'components/common/EnhancedTable/useTable'

import { Direction } from 'services/pageableHelper'
import {
  buildShippingMethod,
  getShippingMethods,
  removeShippingMethod,
  updateShippingMethod,
} from 'services/shippingMethod'
import { validationHandler } from 'services/validation'

import { closeDialog } from 'store/dialog/actionCreator'

import { ShippingMethod } from 'types/ShippingMethods'

import ShippingMethodTable from '../ShippingMethodsTable'
import EditShippingMethodsAside from './EditShippingMethodsAside'

import reducer, {
  initialReducerState,
  closeAside,
  setValidation,
  setPending,
  setShippingMethods,
  setTotalShippingMethods,
  updateOnInputChange,
  editShippingMethod,
  addNewShippingMethod,
} from './EditShippingMethodsReducer'

import { formValidationSchema } from './formValidationSchema'

const StyledText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const StyledButtonContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
})

export interface Props {
  isOpen: boolean
}

export const EditShippingMethods = ({ isOpen }: Props) => {
  const { table } = useTable({
    direction: Direction.ASC,
    orderBy: 'shipping_method',
    page: 0,
    perPage: 20,
  })
  const globalDispatch = useDispatch()
  const [state, dispatch] = React.useReducer(reducer, initialReducerState)

  const fetchShippingMethods = React.useCallback(async () => {
    dispatch(setPending(true))

    const response = await getShippingMethods(table.state)
    dispatch(setShippingMethods(response.data))
    dispatch(setTotalShippingMethods(response.total))
    dispatch(setPending(false))
  }, [table.state])

  React.useEffect(() => {
    fetchShippingMethods()
  }, [fetchShippingMethods, table.state])

  const confirmAction = (): boolean => {
    const { isAsideOpen } = state
    if (!isAsideOpen) {
      return true
    }
    return window.confirm('Are you sure?')
  }

  const handleRequestClose = () => {
    if (confirmAction()) {
      globalDispatch(closeDialog())
    }
  }

  const handleCloseAside = () => {
    dispatch(closeAside())
  }

  const handleAddNew = () => {
    if (confirmAction()) {
      dispatch(addNewShippingMethod())
    }
  }

  const handleEdit = (element: ShippingMethod) => () => {
    if (confirmAction()) {
      dispatch(editShippingMethod(element))
    }
  }

  const handleDelete = (element: ShippingMethod) => async () => {
    if (
      element?.id &&
      window.confirm(
        `Are you sure you want to delete ${element.shipping_method}?`,
      )
    ) {
      await removeShippingMethod(element.id)

      await fetchShippingMethods()
    }
  }

  const handleChange =
    ({ type }: { type: string }) =>
    (value: Nullable<string>) => {
      dispatch(updateOnInputChange(type, value))

      const { validation, isValid } = validationHandler(formValidationSchema, {
        ...{
          method: state.method,
          carrier: state.carrier,
          scac: state.scac,
          csc: state.csc,
          mode: state.mode,
          routing: state.routing,
          isApprovedForPartners: state.isApprovedForPartners,
        },
        [type]: value,
      })

      dispatch(setValidation({ validation, isValid }))
    }

  const handleSubmit = async () => {
    if (!state.mode && !state.routing) {
      const validation = {
        mode: ['Mode is required'],
        routing: ['Routing is required'],
      }
      dispatch(setValidation({ validation, isValid: true }))
      return
    }

    if (state.isValid) {
      const data = buildShippingMethod({
        id: state.shippingMethod?.id,
        method: state.method,
        carrier: state.carrier,
        scac: state.scac,
        csc: state.csc,
        mode: state.mode,
        routing: state.routing,
        isApprovedForPartners: state.isApprovedForPartners,
      })

      try {
        await updateShippingMethod(data)

        if (state.shippingMethod) {
          handleCloseAside() // close on edit
        } else {
          dispatch(addNewShippingMethod()) // reset on add
        }
        fetchShippingMethods()
      } catch (e) {
        console.error(`Submit Failed:: ${e}`)
      }
    }
  }

  const asideTitle = state.shippingMethod ? 'Edit' : 'Add New'

  return (
    <FullScreenDialogContainer
      title="Edit Shipping Methods"
      onRequestClose={handleRequestClose}
      isOpen={isOpen}
      isAsideOpen={state.isAsideOpen}
      asideTitle={asideTitle}
      aside={
        <EditShippingMethodsAside
          validation={state.validation}
          isValid={state.isValid}
          shippingMethod={state.shippingMethod}
          method={state.method}
          carrier={state.carrier}
          scac={state.scac}
          csc={state.csc}
          mode={state.mode}
          routing={state.routing}
          isApprovedForPartners={state.isApprovedForPartners}
          onRequestChange={handleChange}
          onRequestCancel={handleCloseAside}
          onRequestSubmit={handleSubmit}
        />
      }
    >
      <StyledText>
        Shipping methods are the acceptable way a Target Plus package can be
        delivered to our guests. Partners are required to use one of the
        shipping methods below.
      </StyledText>
      <StyledButtonContainer>
        <Button
          data-testid="add-new-button"
          color="primary"
          onClick={handleAddNew}
        >
          add new shipping method
        </Button>
      </StyledButtonContainer>

      <ShippingMethodTable
        table={table}
        data={state.shippingMethods}
        isPending={state.pending}
        total={state.total}
        edit={handleEdit}
        remove={handleDelete}
      />
    </FullScreenDialogContainer>
  )
}

export default EditShippingMethods
