import axios from 'axios'
import apiConfig from 'config/apiConfig'

interface PollReportParams {
  reportId: string
  sellerId?: string
}

export const getPollReport = async ({
  reportId,
  sellerId,
}: PollReportParams): Promise<any> => {
  const url = sellerId
    ? `${apiConfig.sms}/sellers/${sellerId}/report_requests/${reportId}`
    : `${apiConfig.sms}/report_requests/${reportId}`

  const { data } = await axios.get(url)
  return data
}
