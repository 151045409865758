import axios from 'axios'
import apiConfig from 'config/apiConfig'

interface Parameters {
  return_performance_report_types?: string[]
  include_metadata?: boolean
  is_current_view?: boolean
  division_id?: string
  category_id?: string
  item_type_id?: string[]
}

interface Payload {
  type: string
  start_date: string
  end_date: string
  parameters: Parameters
  format: string
}

interface CreateReportType {
  sellerId?: string
  payload: Payload
}

export async function createReport({
  sellerId,
  payload,
}: CreateReportType): Promise<any> {
  const url = sellerId
    ? `${apiConfig.sms}/sellers/${sellerId}/report_requests`
    : `${apiConfig.sms}/report_requests`

  const { data } = await axios.post(url, payload)
  return data
}
