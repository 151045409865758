import { postSellerExecuteCard } from 'v2/services'

const sellerExecuteCardQuery = async ({
  divisionId,
  categoryId,
  itemTypeIds,
  interval,
  sellerId,
  vmmId,
  cardId,
}: {
  divisionId?: string | null
  categoryId?: string | null
  itemTypeIds?: string[]
  interval: string
  sellerId: string
  vmmId: string
  cardId: number
}) => {
  const payload = {
    filters: [
      ...(vmmId
        ? [
            {
              dimension: 'vendor_id',
              pattern: String(vmmId),
              type: 'in',
            },
          ]
        : []),
      ...(itemTypeIds?.length
        ? [
            {
              dimension: 'item_type',
              pattern: itemTypeIds,
              type: 'in',
            },
          ]
        : []),
      ...(divisionId
        ? [
            {
              dimension: 'division_id',
              pattern: divisionId,
              type: 'in',
            },
          ]
        : []),
      ...(categoryId
        ? [
            {
              dimension: 'group_id',
              pattern: categoryId,
              type: 'in',
            },
          ]
        : []),
    ],
    time_period: {
      interval,
      calendar_type: 'Fiscal',
      type: 'relative',
    },
  }

  const config = {
    sellerId,
    cardId,
    payload,
  }

  const data = await postSellerExecuteCard(config)

  return data
}

export default sellerExecuteCardQuery
