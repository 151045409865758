import axios from 'axios'

import apiConfig from 'config/apiConfig'

import { PagingParams, getPageable } from './pageableHelper'

import { Promotion, UpdatedPromotion } from 'types/Promotion'
import { CollectionResponse } from 'types/Response'

type PromotionsSearchParams = {
  divisionId?: string
  pyramidId?: string
  promotionId?: string
  promotionStatus?: string
  effectiveDate?: string
  sellerId?: string
  upstreamStatus?: string
  reviewMilestone?: string
}

export const getPromotions = async (
  pagingParams: PagingParams,
  searchParams: PromotionsSearchParams,
): Promise<CollectionResponse<UpdatedPromotion>> => {
  const pageable = pagingParams ? getPageable(pagingParams) : {}

  const division = searchParams.divisionId
    ? parseInt(searchParams.divisionId)
    : undefined

  const pyramid = searchParams.pyramidId
    ? parseInt(searchParams.pyramidId)
    : undefined

  const promotion = searchParams.promotionId
    ? parseInt(searchParams.promotionId)
    : undefined

  let config = {
    params: {
      ...pageable,
    },
  }

  if (division) {
    Object.assign(config.params, { division_id: division })
  }

  if (pyramid) {
    Object.assign(config.params, { pyramid_id: pyramid })
  }

  if (promotion) {
    Object.assign(config.params, { promotion_id: promotion })
  }

  if (searchParams.promotionStatus) {
    Object.assign(config.params, { status: searchParams.promotionStatus })
  }

  if (searchParams.effectiveDate) {
    Object.assign(config.params, { effective_date: searchParams.effectiveDate })
  }

  if (searchParams.upstreamStatus) {
    Object.assign(config.params, {
      upstream_status: searchParams.upstreamStatus,
    })
  }

  if (searchParams.reviewMilestone) {
    Object.assign(config.params, {
      review_milestone: searchParams.reviewMilestone,
    })
  }

  let res
  if (searchParams.sellerId) {
    const { seller_id: any, ...newParams } = config.params as {
      seller_id: string
    }
    const newConfig = {
      ...config,
      params: newParams,
    }

    res = await axios.get(
      `${apiConfig.sellerPromotions}/sellers/${searchParams.sellerId}/promotions`,
      newConfig,
    )
  } else {
    res = await axios.get(`${apiConfig.sellerPromotions}/promotions`, config)
  }

  return { data: res.data, total: parseInt(res.headers['x-total-count'], 10) }
}

export const getPromotionById = async (
  promotionId: string,
): Promise<Promotion[]> => {
  let config = {
    params: {
      promotion_id: promotionId,
    },
  }

  return axios
    .get(`${apiConfig.sellerPromotions}/promotions`, config)
    .then((res) => {
      return res.data
    })
}

export const updatePromotion = async (
  promotion: UpdatedPromotion,
): Promise<Promotion> => {
  return axios
    .put(`${apiConfig.sellerPromotions}/promotions/${promotion.id}`, promotion)
    .then((res) => {
      return res.data
    })
}

export const deletePromotion = async (
  promotion: Promotion,
): Promise<Promotion> => {
  return axios
    .delete(`${apiConfig.sellerPromotions}/promotions/${promotion.id}`)
    .then((res) => res.data)
}
