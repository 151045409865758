import * as yup from 'yup'

export const formValidationSchema = yup.object().shape({
  method: yup.string().max(50).required('Shipping method is required'),
  carrier: yup.string().max(20).required('Carrier is required'),
  scac: yup.string().max(20).required('SCAC is required'),
  csc: yup.string().max(20).required('CSC is required'),
  mode: yup.string().max(20).required('Mode is required'),
  routing: yup.string().max(20).required('Routing is required'),
})
