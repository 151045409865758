import apiConfig, {
  ENVIRONMENT_PRODUCTION,
  EXTERNAL_PROD_HOSTNAME,
  EXTERNAL_STAGE_HOSTNAME,
} from 'config/apiConfig'

export interface IQueAndLink {
  question: string
  link: string
}

export interface IHelpArticleQueAndLink {
  [key: string]: IQueAndLink
}

const preQuestion = `How to reduce returns for`
let envUrl
if (apiConfig.environment === ENVIRONMENT_PRODUCTION) {
  envUrl = `https://${EXTERNAL_PROD_HOSTNAME}`
} else {
  envUrl = `https://${EXTERNAL_STAGE_HOSTNAME}`
}
const helpArticleQueAndLink: IHelpArticleQueAndLink = {
  'APPAREL/ACCESS': {
    question: `${preQuestion} Apparel and Accessories?`,
    link: `${envUrl}/article/000153354`,
  },
  'ESSENTIALS & BEAUTY': {
    question: `${preQuestion} Essentials & Beautry?`,
    link: ` ${envUrl}/article/000104875`,
  },
  'FOOD/BEVERAGE': {
    question: `${preQuestion} Food/Beverage?`,
    link: `${envUrl}/article/000180240`,
  },
  HARDLINES: {
    question: `${preQuestion} Hardlines?`,
    link: `${envUrl}/article/000180241`,
  },
  HOME: {
    question: `${preQuestion} Home?`,
    link: `${envUrl}/article/000148869`,
  },
}

export const getReasonsList = (response: any): IQueAndLink[] => {
  return (
    response?.[0]?.BUs?.split(',')?.reduce(
      (list: IQueAndLink[], bu: string) => {
        if (helpArticleQueAndLink[bu]) {
          list.push(helpArticleQueAndLink[bu])
        }
        return list
      },
      [],
    ) ?? []
  )
}
