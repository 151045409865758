import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { currentSellerId, currentSellerVmmId } from 'store/selectors'
import { toTitleCase } from 'v2/utils/helper'
import { postSellerExecuteCard } from 'v2/services'
import { GREEN_FIELD_CARD_ID } from 'v2/constant/greenFieldCardID'

export type GreenFieldItemTypeAutoCompleteOptionType = {
  id: string
  value: string
  label: string
}

const useGreenFieldItemTypeAutoComplete = () => {
  const sellerId = useSelector(currentSellerId) ?? ''
  const vmmId = useSelector(currentSellerVmmId) ?? ''
  const [itemTypeOptions, setItemTypeOptions] = useState<
    GreenFieldItemTypeAutoCompleteOptionType[]
  >([])
  const [selectedItemTypOption, setSelectedItemTypOption] = useState<
    GreenFieldItemTypeAutoCompleteOptionType[] | null
  >(null)

  useEffect(() => {
    const config = {
      sellerId,
      cardId: GREEN_FIELD_CARD_ID.ITEM_TYPE_FILTER,
      payload: {
        filters: [
          ...(vmmId
            ? [
                {
                  dimension: 'vendor_id',
                  pattern: String(vmmId),
                  type: 'in',
                },
              ]
            : []),
        ],
      },
    }
    postSellerExecuteCard(config).then(
      (response: { item_type_id: string; item_type: string }[]) => {
        const divisions = response.map(({ item_type_id, item_type }) => ({
          id: item_type_id,
          value: item_type_id,
          label: toTitleCase(item_type),
        }))
        setItemTypeOptions(divisions)
      },
    )
  }, [sellerId, vmmId])

  const onItemTypUpdate = (
    _id: string,
    value: GreenFieldItemTypeAutoCompleteOptionType[],
  ) => {
    setSelectedItemTypOption(value)
  }

  return {
    selectedItemTypOption,
    itemTypeOptions,
    onItemTypUpdate,
  }
}

export default useGreenFieldItemTypeAutoComplete
