import { FC, useMemo } from 'react'
import { Tooltip } from '@enterprise-ui/canvas-ui-react'

import { toTitleCase } from 'v2/utils/helper'
import { StyledReturnReason } from '../styles'

interface ReturnReasonProps {
  returnReasons: {
    return_percentage: number
    return_reason: string
  }[]
}

const ReturnReason: FC<ReturnReasonProps> = ({ returnReasons }) => {
  const allReasons = useMemo(
    () =>
      returnReasons
        .map((reason: any) => toTitleCase(reason?.return_reason))
        .join(', '),
    [returnReasons],
  )

  const toolTipText = useMemo(
    () =>
      returnReasons
        .map(
          (reason: any) =>
            `${toTitleCase(
              reason?.return_reason,
            )} - ${reason?.return_percentage}%`,
        )
        .join(', '),
    [returnReasons],
  )

  if (!returnReasons?.length) {
    return <></>
  }

  return (
    <Tooltip location="top-right" content={toolTipText}>
      <StyledReturnReason
        tabIndex={0}
        className="hc-ws-no-wrap hc-to-ellipsis hc-ov-hidden"
      >
        {allReasons}
      </StyledReturnReason>
    </Tooltip>
  )
}

export default ReturnReason
