import { useState, useEffect } from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { Button } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  PlusCircleIcon,
  MinusCircleIcon,
} from '@enterprise-ui/icons'

import Attribute, { ITEM_SUBTYPE } from 'types/Attribute'
import { TaxonomyTypeahead } from 'components/common/Typeahead'
import AllowedItemtype from './AllowedItemtype'
import { StyledAlert, StyledAutocompleteGrid } from '../styles'
import { AllowedProductSubType } from 'types/Promotion'
import WarningIcon from 'components/common/WarningIcon'

interface OwnProps {
  allowedProductSubtypes: any
  setAllowedProductSubtypes: Function
}

const ProductTypes = ({
  allowedProductSubtypes,
  setAllowedProductSubtypes,
}: OwnProps) => {
  const [productSubtypeError, setProductSubtypeError] = useState(false)

  useEffect(() => {
    if (!allowedProductSubtypes?.length) {
      setAllowedProductSubtypes([
        {
          id: '',
          name: '',
          allowedItemTypes: [],
        },
      ])
    }
  }, [allowedProductSubtypes, setAllowedProductSubtypes])

  const onItemSubtypeChange = (
    attribute: Nullable<Attribute>,
    index: number,
  ) => {
    if (allowedProductSubtypes.some((data: any) => data.id === attribute?.id)) {
      setProductSubtypeError(true)
    } else {
      setProductSubtypeError(false)
      setAllowedProductSubtypes((prevState: any[]) =>
        prevState.map((data, i) => {
          if (index === i) {
            return {
              id: attribute?.id ?? '',
              name: attribute?.name ?? '',
              slectedItemTypeId: [],
              isPreSelcted: false,
            }
          }
          return data
        }),
      )
    }
  }

  const onAddOrDeleteClick = (isAddButton: boolean, index?: number) => {
    if (isAddButton) {
      setAllowedProductSubtypes((prevState: any) => [
        ...prevState,
        {
          id: '',
          name: '',
          allowedItemTypes: [],
        },
      ])
    } else {
      setAllowedProductSubtypes((prevState: any[]) =>
        prevState.filter((_data, i) => index !== i),
      )
    }
  }

  // Disabling the Add button if no selections are made.
  const hasAddButtonDisabled = (allowedProductSubtypes: any): boolean => {
    const lastElement = [...allowedProductSubtypes].pop()
    return !(lastElement?.id && lastElement?.selectedItemTypes?.length)
  }

  return (
    <>
      {allowedProductSubtypes.every(
        (allowedProductSubtype: AllowedProductSubType) =>
          allowedProductSubtype.id.length === 0,
      ) && (
        <StyledAlert className="hc-mb-normal">
          <WarningIcon>
            At least one product subtype is required before saving the
            promotion.
          </WarningIcon>
        </StyledAlert>
      )}
      {allowedProductSubtypes?.map(
        (productSubtype: any, index: number, thisData: any) => (
          <Grid.Container align="flex-end" key={index}>
            <Grid.Item xs={5}>
              <label className="C-Input--type-label">
                {index === 0 ? 'Allowed Product Subtypes *' : ''}
              </label>
              <TaxonomyTypeahead
                placeholder="Search Product Subtype"
                searchType={ITEM_SUBTYPE}
                onChange={(attribute) => onItemSubtypeChange(attribute, index)}
                value={
                  productSubtype?.id
                    ? {
                        id: productSubtype.id,
                        name: productSubtype.name,
                      }
                    : null
                }
              />
            </Grid.Item>
            <StyledAutocompleteGrid xs={5}>
              {productSubtype?.id && (
                <AllowedItemtype
                  index={index}
                  setAllowedProductSubtypes={setAllowedProductSubtypes}
                  subTypeId={productSubtype.id}
                  slectedItemTypeId={productSubtype.slectedItemTypeId}
                  isPreSelcted={productSubtype.isPreSelcted}
                />
              )}
            </StyledAutocompleteGrid>
            <Grid.Item xs={2}>
              <Button
                data-testid={`remove-button-${index}`}
                fullWidth
                size="expanded"
                type="secondary"
                onClick={() => onAddOrDeleteClick(false, index)}
                disabled={thisData.length === 1}
              >
                <Grid.Container align="center" justify="center" spacing="dense">
                  <Grid.Item>REMOVE</Grid.Item>
                  <Grid.Item>
                    <EnterpriseIcon icon={MinusCircleIcon} />
                  </Grid.Item>
                </Grid.Container>
              </Button>
            </Grid.Item>
          </Grid.Container>
        ),
      )}
      {productSubtypeError && (
        <p className="hc-mt-dense hc-clr-error">
          You have selected the same Product Subtype more than once *
        </p>
      )}
      {allowedProductSubtypes?.length <= 10 && (
        <Grid.Container>
          <Grid.Item xs={2}>
            <Button
              fullWidth
              size="expanded"
              type="secondary"
              onClick={() => onAddOrDeleteClick(true)}
              disabled={hasAddButtonDisabled(allowedProductSubtypes)}
            >
              <Grid.Container align="center" justify="center" spacing="dense">
                <Grid.Item>ADD</Grid.Item>
                <Grid.Item>
                  <EnterpriseIcon icon={PlusCircleIcon} />
                </Grid.Item>
              </Grid.Container>
            </Button>
          </Grid.Item>
        </Grid.Container>
      )}
    </>
  )
}

export default ProductTypes
