import { FC, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { Placeholder, Grid } from '@enterprise-ui/canvas-ui-react'

import { toTitleCase, roundToTwoDecimals } from 'v2/utils/helper'
import { TopSkuTypes } from 'v2/types/topSkuType'
import { currentSellerId, currentSellerVmmId } from 'store/selectors'
import { useReturnInsightsStore } from 'v2/store'
import { sellerExecuteCardQuery } from 'v2/query'
import ReturnReason from '../ReturnReason'
import { StyledTable } from '../styles'
import { ViewType } from '.'

interface OwnProps {
  cardId: number
  view: ViewType
}

const TopSKUTypeTable: FC<OwnProps> = ({ cardId, view }) => {
  const sellerId = useSelector(currentSellerId) ?? ''
  const vmmId = useSelector(currentSellerVmmId) ?? ''
  const [rowData, setRowData] = useState<TopSkuTypes[]>()
  const {
    selectedTopSkuTypeColumns,
    divisionId,
    categoryId,
    itemTypeIds,
    interval,
    selectedVmmId,
  } = useReturnInsightsStore()
  const { tcin, category, upc, department, division } =
    selectedTopSkuTypeColumns

  const { data, isError, isFetching } = useQuery(
    [
      'TOP_SKU_TYPE',
      {
        divisionId,
        categoryId,
        itemTypeIds,
        interval,
        sellerId,
        vmmId,
        cardId,
        selectedVmmId,
      },
    ],
    () =>
      sellerExecuteCardQuery({
        divisionId,
        categoryId,
        itemTypeIds,
        interval,
        sellerId,
        cardId,
        vmmId: selectedVmmId || vmmId,
      }),
  )

  useEffect(() => {
    if (data) {
      const mappedData: TopSkuTypes[] = data.map(
        (item: any): TopSkuTypes => ({
          tcin: item.tcin,
          partnerSku: item.partner_sku,
          itemName: item.item_type,
          upc: item.upc,
          category: item?.category_name,
          parentTcin: item?.parent_tcin,
          returnRate:
            typeof item.return_rate === 'number'
              ? `${roundToTwoDecimals(item.return_rate)}%`
              : '',
          benchmark:
            typeof item.benchmark === 'number'
              ? `${roundToTwoDecimals(item.benchmark)}%`
              : '',
          returnGmv:
            typeof item.return_gmv === 'number'
              ? `$${roundToTwoDecimals(item.return_gmv)?.toLocaleString()}`
              : '',
          division: toTitleCase(item.division_name),
          department: toTitleCase(item.department_name),
          returnReasons: {
            cellDisplay: (
              <ReturnReason
                returnReasons={item.top_three_return_reasons ?? []}
              />
            ),
          },
        }),
      )

      setRowData(mappedData)
    }
  }, [data])

  const columnDefs = useMemo(
    () =>
      [
        {
          field: 'partnerSku',
          headerName: 'Partner SKU',
        },
        view === ViewType.VC_VIEW
          ? {
              field: 'parentTcin',
              headerName: 'Parent TCIN',
            }
          : null,

        {
          field: 'itemName',
          headerName: 'Item Name',
        },
        {
          field: 'returnRate',
          headerName: 'Return Rate',
        },
        {
          field: 'benchmark',
          headerName: 'Benchmark',
          tooltipText:
            'Threshold that defines the acceptable return rate limit based on the item type',
        },
        {
          field: 'returnGmv',
          headerName: 'Return GMV',
        },
        {
          field: 'returnReasons',
          headerName: 'Return Reasons',
          tooltipText: 'Please hover to see the top three reasons',
        },
        tcin
          ? {
              field: 'tcin',
              headerName: 'TCIN',
            }
          : null,
        upc
          ? {
              field: 'upc',
              headerName: 'UPC',
            }
          : null,
        category
          ? {
              field: 'category',
              headerName: 'Category',
            }
          : null,
        division
          ? {
              field: 'division',
              headerName: 'Division',
            }
          : null,
        department
          ? {
              field: 'department',
              headerName: 'Department',
            }
          : null,
      ].filter(Boolean),
    [upc, division, department, tcin, category, view],
  )

  if (isFetching) {
    return (
      <Placeholder className="hc-pa-normal">
        <Grid.Container justify="space-between" spacing="dense">
          {Array.from({ length: 28 }).map((_, index) => (
            <Grid.Item key={index} xs={3}>
              <Placeholder.Rect height="30px" />
            </Grid.Item>
          ))}
        </Grid.Container>
      </Placeholder>
    )
  }

  if (isError) {
    return (
      <div className="hc-pa-expanded hc-ta-center">
        <p>There was an error fetching the data</p>
      </div>
    )
  }

  return (
    <>
      {rowData?.length ? (
        <StyledTable
          scrollableWidth
          alternateRowColor
          id="top-sku-type-table"
          cellPadding="dense"
          data={{
            columnDefs,
            rowData,
          }}
        />
      ) : (
        <div className="hc-pa-expanded hc-ta-center">
          <p>No results found!</p>
        </div>
      )}
    </>
  )
}

export default TopSKUTypeTable
