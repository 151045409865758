import { FC, useState, useEffect } from 'react'
import { Button, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  ChartBarHorizontalIcon,
  MaximizeIcon,
} from '@enterprise-ui/icons'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'

import { GREEN_FIELD_CARD_ID } from 'v2/constant/greenFieldCardID'
import { useReturnInsightsStore } from 'v2/store'
import { getDifferenceInDays } from 'v2/utils/date'
import ReportDownload from '../ReportDownload'
import ReturnRateGraph from './ReturnRateGraph'
import { ReturnInformation } from './ReturnInformation'

import {
  StyledReturnRateCardContainer,
  StyledCompareReturnRateCardGridContainer,
  StyledDivider,
} from '../styles'
import ReturnRateModal from './ReturnRateModal'

export enum ViewType {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
}

type TimeFrame = {
  startDate: string
  endDate: string
}

const ReturnRate: FC = () => {
  const [cardId, setCardId] = useState(GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_DAY)
  const [view, setView] = useState<ViewType>(ViewType.DAY)
  const [datePickerError, setDatePickerError] = useState('')
  const [timeFrame, updateTimeFrame] = useState<TimeFrame | null>(null)
  const [isMaximized, setIsMaximized] = useState(false)
  const {
    timeFrame: primaryTimeFrame,
    isCompareViewEnabled,
    updateCompareView,
    updateCompareTimeFrame,
    clearCompareInterval,
    datePickerDisableDate,
  } = useReturnInsightsStore()

  useEffect(() => {
    if (isCompareViewEnabled) {
      clearCompareState()
    }
  }, [isCompareViewEnabled])

  useEffect(() => {
    if (view === ViewType.MONTH)
      setCardId(GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_MONTH)
    else if (view === ViewType.WEEK)
      setCardId(GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_WEEK)
    else if (view === ViewType.DAY)
      setCardId(GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_DAY)
  }, [view])

  const onDatePickerUpdate = (_id: string, value: any) => {
    if (value.startDate && value.endDate) {
      updateTimeFrame(value)
    } else {
      updateTimeFrame(null)
    }
    setDatePickerError('')
  }

  const onApplyClick = () => {
    const compareDay = getDifferenceInDays(
      timeFrame?.startDate!,
      timeFrame?.endDate!,
    )
    const primaryDay = getDifferenceInDays(
      primaryTimeFrame?.startDate!,
      primaryTimeFrame?.endDate!,
    )

    if (
      timeFrame?.startDate === primaryTimeFrame?.startDate &&
      timeFrame?.endDate === primaryTimeFrame?.endDate
    ) {
      setDatePickerError(
        'Comparison period cannot be same as Primary period. Please select another period.',
      )
    } else if (compareDay !== primaryDay) {
      setDatePickerError(
        'Comparison period should have same number of days as Primary period. Please select another period.',
      )
    } else {
      updateCompareTimeFrame(timeFrame!)
      clearCompareState()
      updateCompareView(false)
    }
  }

  const clearCompareState = () => {
    updateTimeFrame(null)
    setDatePickerError('')
  }

  const onClickClose = () => {
    updateCompareView(!isCompareViewEnabled)
    clearCompareState()
  }

  const handleViewClick = (view: ViewType) => {
    setView(view)
    clearCompareInterval()
  }

  return (
    <StyledReturnRateCardContainer className="hc-pa-normal">
      <Grid.Container justify="space-between">
        <Grid.Item xs={6}>
          <Heading size={4}>RETURN RATE</Heading>
          <p className="hc-clr-grey02 hc-fs-xs">
            % return sales for the orders that were placed in the selected time
            period
          </p>
        </Grid.Item>
        {!isCompareViewEnabled && (
          <Grid.Item>
            <Grid.Container align="center" justify="flex-end">
              <Grid.Item>
                <Button
                  data-testid="return-rate-compare"
                  type="secondary"
                  size="normal"
                  disabled={view !== ViewType.DAY}
                  onClick={() => updateCompareView(!isCompareViewEnabled)}
                >
                  <EnterpriseIcon icon={ChartBarHorizontalIcon} size="sm" />
                  Compare
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button
                  data-testid="return-rate-compare"
                  type="secondary"
                  size="normal"
                  variant="contained"
                  onClick={() => setIsMaximized(true)}
                >
                  <EnterpriseIcon icon={MaximizeIcon} size="sm" />
                </Button>
              </Grid.Item>
              <Grid.Item>
                <ReportDownload
                  returnPerformanceReportTypes={['RETURN_RATE']}
                  fileName="return-rate"
                />
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        )}
      </Grid.Container>
      <StyledDivider className="hc-mt-xs" />
      {isMaximized && (
        <ReturnRateModal
          isMaximized={isMaximized}
          setIsMaximized={setIsMaximized}
          cardId={cardId}
          view={view}
          handleViewClick={handleViewClick}
        />
      )}
      {isCompareViewEnabled ? (
        <StyledCompareReturnRateCardGridContainer
          className="hc-pa-normal"
          justify="center"
          align="center"
        >
          <Grid.Item xs={10}>
            <DatePicker
              id="return-insights-compare-date-picker"
              type="gregorian-range"
              label="Compare with"
              error={!!datePickerError}
              errorText={datePickerError}
              onUpdate={onDatePickerUpdate}
              value={timeFrame}
              hintText="Returns data cannot be accessed for recent 30 days"
              disableDates={{ after: datePickerDisableDate }}
            />
            <Grid.Container className="hc-mt-normal" justify="flex-end">
              <Grid.Item>
                <Button
                  type="secondary"
                  size="normal"
                  variant="contained"
                  onClick={onClickClose}
                >
                  Close
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button
                  type="primary"
                  size="normal"
                  variant="contained"
                  onClick={onApplyClick}
                  disabled={timeFrame === null}
                >
                  Apply
                </Button>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </StyledCompareReturnRateCardGridContainer>
      ) : (
        <Grid.Container className="hc-pa-normal">
          <Grid.Item xs={3}>
            <ReturnInformation />
          </Grid.Item>
          <Grid.Item xs={9}>
            <ReturnRateGraph
              cardId={cardId}
              view={view}
              handleViewClick={handleViewClick}
            />
          </Grid.Item>
        </Grid.Container>
      )}
    </StyledReturnRateCardContainer>
  )
}

export default ReturnRate
