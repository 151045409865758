import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import HeaderTitle from 'components/common/HeaderTitle'
import MetaContent from './MetaContent'
import NameAndAddressContent from './DisplayContent'
import DiversityContent from './DiversityContent'

import { hydrateAddress } from 'services/addressHelper'
import { isUserRoleSellerAdmin, isOneOfUserRoles } from 'services/authorization'
import { getPrettyName } from 'services/enumerations'
import { USER_ROLE_ADMIN, USER_ROLE_OPS } from 'services/roles'

import { fetchPartnerDiversityAnswers } from 'store/partnerDiversity/actionCreators'

import { EnumerationName } from 'types/Enumeration'
import {
  currentSeller,
  getDiversityAnswers,
  getMemberOf,
  isRoleExternalUserSelector,
} from 'store/selectors'
import { getSellerInternal } from 'v2/services/getSellerInternal'

export const BasicInfoPage = () => {
  const reduxDispatch = useDispatch()

  const seller = useSelector(currentSeller)
  const memberOf = useSelector(getMemberOf)
  const diversityAnswers = useSelector(getDiversityAnswers)

  const isExternalUser = useSelector(isRoleExternalUserSelector)
  const [gmvPotential, setGmvPotential] = useState('')

  useEffect(() => {
    if (!isExternalUser) {
      const fetchData = async () => {
        const results = await getSellerInternal(seller?.id ?? '')
        setGmvPotential(results?.gmv_potential ?? 'N/A')
      }
      fetchData()
    }
  }, [isExternalUser, seller?.id])

  React.useEffect(() => {
    if (seller?.is_private) {
      reduxDispatch(fetchPartnerDiversityAnswers(seller.id))
    }
  }, [reduxDispatch, seller])

  if (!seller) return null

  const isProductAdminOrOps = isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_OPS,
  ])
  const primaryAddress = hydrateAddress(seller?.primary_address || {})
  const taxSetupComplete = seller?.internal_tax_setting.setup_complete ?? false

  const sourceLabel = getPrettyName({
    enumeration: EnumerationName.SOURCE,
    value: seller.source,
  })

  return (
    <div>
      <HeaderTitle title="Basic Info & Display" />
      <MetaContent
        sellerId={seller.id}
        taxId={seller.tax_id}
        vmmId={seller.vmm_id}
        stripeId={seller.stripe_account_id}
        businessStructure={seller.business_structure}
        sourceLabel={sourceLabel}
        status={seller.status}
        memberOf={memberOf}
        setupComplete={taxSetupComplete}
        inOtherMarketplaces={seller.in_other_marketplaces}
        isPrivate={seller.is_private}
        canEditStatus={isProductAdminOrOps}
        plannedSku={seller.planned_skus}
        gmvPotential={gmvPotential}
      />
      <NameAndAddressContent
        address={primaryAddress}
        status={seller.status}
        description={seller.description}
        displayName={seller.display_name}
        guestServicesEmail={seller.guest_services_email}
        guestServicesPhone={seller.guest_services_phone_number}
        headerImage={seller.header_image}
        isImporter={seller.is_importer_of_consumer_product}
        isManufacturer={seller.is_manufacturer_of_consumer_product}
        isReseller={seller.is_reseller_of_consumer_product}
        legalBusinessName={seller.legal_business_name}
        logoImage={seller.logo_image}
        privacyPolicy={seller.privacy_policy}
        reviewIndicators={seller.review_indicators}
        sellerId={seller.id}
        vmmId={seller.vmm_id}
        isEditableExternal={
          isUserRoleSellerAdmin(memberOf) || isProductAdminOrOps
        }
        isEditableInternal={isProductAdminOrOps}
      />
      {seller.is_private && (
        <DiversityContent
          answers={diversityAnswers}
          isEditable={isUserRoleSellerAdmin(memberOf) || isProductAdminOrOps}
        />
      )}
    </div>
  )
}

export default BasicInfoPage
