import React from 'react'

import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

export interface Props {
  trackingNumber: string
  scac?: string
  shippingMethod?: string
}

const getFedExTrackingUrl = (trackingNumber: string) =>
  `https://www.fedex.com/fedextrack/?tracknumbers=${trackingNumber}`

const getUpsTrackingUrl = (trackingNumber: string) =>
  `https://www.ups.com/track?tracknum=${trackingNumber}`

const getUspsTrackingUrl = (trackingNumber: string) =>
  `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`

const getEfwwTrackingUrl = (trackingNumber: string) =>
  `https://efwtrack.com/track/${trackingNumber}`

const getOnTracTrackingUrl = (trackingNumber: string) =>
  `https://www.ontrac.com/tracking/?number=${trackingNumber}`

const getYrcTrackingUrl = (trackingNumber: string) =>
  `https://my.yrc.com/tools/track/shipments?referenceNumber=${trackingNumber}&referenceNumberType=PRO`

const getAbfTrackingUrl = (trackingNumber: string) =>
  `https://arcb.com/tools/tracking.html#/${trackingNumber}`

export const TrackingNumberLink = ({
  trackingNumber,
  scac,
  shippingMethod,
}: Props) => {
  let content: React.ReactNode = 'No'
  let trackingUrl

  const trackingNumberNoSpaces = trackingNumber.replace(/\s+/g, '')

  if (trackingNumberNoSpaces.length === 12) {
    trackingUrl = getFedExTrackingUrl(trackingNumberNoSpaces)
  }
  if (
    trackingNumberNoSpaces.length === 18 &&
    trackingNumberNoSpaces.startsWith('1Z')
  ) {
    trackingUrl = getUpsTrackingUrl(trackingNumberNoSpaces)
  }
  if (trackingNumberNoSpaces.length === 22) {
    trackingUrl = getUspsTrackingUrl(trackingNumberNoSpaces)
  }

  if (scac) {
    if (
      scac === 'FDEG' ||
      scac === 'XS' ||
      scac === 'FDE' ||
      scac === 'FXSP' ||
      trackingNumberNoSpaces.length === 12
    ) {
      trackingUrl = getFedExTrackingUrl(trackingNumberNoSpaces)
    }
    if (scac.includes('UPS')) {
      trackingUrl = getUpsTrackingUrl(trackingNumberNoSpaces)
    }
    if (scac === 'USPS') {
      trackingUrl = getUspsTrackingUrl(trackingNumberNoSpaces)
    }
    if (scac === 'EFWW') {
      trackingUrl = getEfwwTrackingUrl(trackingNumberNoSpaces)
    }
    if (scac === 'EMSY') {
      trackingUrl = getOnTracTrackingUrl(trackingNumberNoSpaces)
    }
    if (scac === 'YRC') {
      trackingUrl = getYrcTrackingUrl(trackingNumberNoSpaces)
    }
    if (scac === 'ABF') {
      trackingUrl = getAbfTrackingUrl(trackingNumberNoSpaces)
    }
  }

  if (shippingMethod) {
    if (shippingMethod.includes('FedEx')) {
      trackingUrl = getFedExTrackingUrl(trackingNumberNoSpaces)
    }
    if (shippingMethod.includes('UPS')) {
      trackingUrl = getUpsTrackingUrl(trackingNumberNoSpaces)
    }
    if (shippingMethod.includes('USPS')) {
      trackingUrl = getUspsTrackingUrl(trackingNumberNoSpaces)
    }
    if (shippingMethod === 'EFWLTL') {
      trackingUrl = getEfwwTrackingUrl(trackingNumberNoSpaces)
    }
    if (shippingMethod === 'OnTracGround') {
      trackingUrl = getOnTracTrackingUrl(trackingNumberNoSpaces)
    }
  }

  content = trackingUrl ? (
    <Tooltip title="Opens an external website in a new tab">
      <a
        data-testid="tracking-link"
        href={trackingUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {trackingNumber}
      </a>
    </Tooltip>
  ) : (
    trackingNumber
  )

  return <Typography component="span">{content}</Typography>
}

export default TrackingNumberLink
