export const datePattern = {
  minus: {
    yyyy_MM_dd: 'yyyy-MM-dd', // 2024-02-01
  },
  slash: {
    MM_dd_yyyy: 'MM/dd/yyyy', // 02/14/2024
  },
  verbose: {
    MMMM_do_yyyy: 'MMMM do yyyy', //Aug 24th 2024
    MMMM_do_yyyy_time: 'MMMM do yyyy h:mm a',
  },
}
