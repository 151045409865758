import styled from '@emotion/styled'
import { Card, Grid, Heading, Form } from '@enterprise-ui/canvas-ui-react'

export const StyledPromotionModalContainer = styled(Grid.Container)`
  .C-Card {
    background: var(--canvas-background-color--default-contrast-weak);
    border-radius: 8px;
    border: 1px solid var(--canvas-border-color--default-hover);
    box-shadow: none;
  }
  .C-Card.\--elevation-1 {
    box-shadow: none;
  }
`

export const StyledIVPromotionDetailsCard = styled(Card)`
  min-height: 600px;
  p {
    font-size: 14px;
    margin-bottom: 0px;
  }
`

export const StyledPromotionDetailsHeading = styled(Heading)`
  &.C-Heading {
    font-weight: normal;
  }
`
export const StyledPartnerPromotionDetailsCard = styled(Card)`
  p {
    font-size: 12px;
    margin-bottom: 0px;
  }
  .C-Heading {
    font-weight: normal;
  }
  .C-InputRadio {
    display: flex;
  }
  .C-InputRadio__Option {
    padding-right: 30px;
  }
  .time-input {
    width: 100%;
    padding: 4px;
    border-radius: 6px;
    border: 1px solid var(--canvas-border-color--input);
  }
`
export const StyledPromoDetailsGridContainer = styled(Grid.Container)`
  font-size: 14px;
  .C-Input--type-label {
    color: #000;
  }
  .C-Input:hover {
    border: 1px solid var(--canvas-border-color--droparea);
  }
  .C-Input {
    border: 1px solid var(--canvas-border-color--droparea;
    border-radius: 4px;
    font-size: 14px;
    height: 38px;
    background: var(--canvas-background-color--input);
  }
  
  .C-Input.--type-textarea {
    width: 100%;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 1px solid var(--canvas-border-color--droparea);
    border-radius: 4px;
    resize: none;
  }
`

export const StyledFinalStatusContainer = styled(Card)`
  .C-InputRadio {
    display: flex;
    font-size: 16px;
  }
  .C-InputRadio__Option {
    padding-right: 32px;
  }
  .C-Heading {
    font-weight: normal;
  }
`

export const StyledAutocompleteGrid = styled(Grid.Item)`
  .C-Autocomplete {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  C-Input--type-label {
    padding-bottom: 4px;
  }
  .C-Autocomplete__IconArea {
    padding-top: 2px;
  }
  .C-Input--type-info {
    display: none;
    height: 0px;
    margin-top: 0px;
  }
`
export const StyledFinalStatusNote = styled.div`
  background: var(--canvas-background-color--alert-contrast-weak);
  padding: 8px 12px;
`
export const StyledFormField = styled(Form.Field)`
  .C-Input--type-info {
    height: 0px;
    margin-top: 0px;
  }
`
export const StyledAlert = styled.div`
  color: var(--canvas-font-color--alert);
`
