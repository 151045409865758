import { FC, useState } from 'react'
import EnterpriseIcon, { DownloadIcon } from '@enterprise-ui/icons'

import { DowloadReportModal } from 'v2/components/common'
import { useReturnInsightsStore } from 'v2/store'
import { Button } from '@enterprise-ui/canvas-ui-react'

interface ReportDownloadProps {
  fileName: string
  returnPerformanceReportTypes: string[]
  itemTypeIds?: string[]
}

const ReportDownload: FC<ReportDownloadProps> = ({
  fileName,
  returnPerformanceReportTypes,
  itemTypeIds,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [reportPayload, setReportPayload] = useState<any>(null)
  const { timeFrame, view, selectedSellerId, divisionId, categoryId } =
    useReturnInsightsStore()

  const onDownloadClick = () => {
    const payload = {
      type:
        view === 'INTERNAL'
          ? 'RETURN_PERFORMANCE_INTERNAL'
          : 'RETURN_PERFORMANCE',
      format: 'EXCEL',
      start_date: new Date(timeFrame.startDate).toISOString(),
      end_date: new Date(timeFrame.endDate).toISOString(),
      ...(selectedSellerId && { seller_id: selectedSellerId }),
      parameters: {
        include_metadata: true,
        is_current_view: false,
        return_performance_report_types: returnPerformanceReportTypes,
        ...(divisionId && { division_id: divisionId }),
        ...(categoryId && { category_id: categoryId }),
        ...((itemTypeIds ?? []).length > 0 && { item_type_id: itemTypeIds }),
      },
    }
    setReportPayload(payload)
    setIsModalOpen(true)
  }

  return (
    <>
      <Button
        className="hc-pl-xs hc-pr-xs"
        type="secondary"
        onClick={onDownloadClick}
      >
        <EnterpriseIcon size="lg" icon={DownloadIcon} />
      </Button>
      <DowloadReportModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        reportRequestPayload={reportPayload}
        fileName={fileName}
      />
    </>
  )
}

export default ReportDownload
