import axios from 'axios'

import { getPageable, PagingParams } from './pageableHelper'

import apiConfig from 'config/apiConfig'
import { ShippingMethod } from 'types/ShippingMethods'
import { CollectionResponse } from 'types/Response'

export function getShippingMethods(params: PagingParams) {
  const config = {
    params: getPageable(params),
  }
  return axios
    .get(`${apiConfig.sellerOrders}/shipping_methods/internal`, config)
    .then((res): CollectionResponse<ShippingMethod> => {
      const { data, headers } = res
      const total = headers['x-total-count']
        ? parseInt(headers['x-total-count'], 10)
        : 0

      return {
        total,
        data,
      }
    })
}

export const buildShippingMethod = ({
  id,
  method,
  carrier,
  scac,
  csc,
  routing,
  mode,
  isApprovedForPartners,
}: {
  id: string | undefined
  method: string
  carrier: string
  scac: string
  csc: string
  mode: string
  routing: string
  isApprovedForPartners: boolean
}): ShippingMethod => ({
  id,
  shipping_method: method,
  carrier,
  scac,
  mode,
  routing,
  service_code: csc,
  approved_for_partners: isApprovedForPartners,
})

export const updateShippingMethod = async (data: ShippingMethod) => {
  if (data.id) {
    return axios
      .put(`${apiConfig.sellerOrders}/shipping_methods/${data.id}`, data)
      .then((res) => res.data)
  }

  return axios
    .post(`${apiConfig.sellerOrders}/shipping_methods`, data)
    .then((res) => res.data)
}

export const removeShippingMethod = async (shippingMethodId: string) => {
  return axios
    .delete(`${apiConfig.sellerOrders}/shipping_methods/${shippingMethodId}`)
    .then((res) => res.data)
}

export const getMaximumRolloverDays = (max: number, target: number) =>
  Math.floor(max / target)
