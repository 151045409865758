import styled from '@emotion/styled'
import { grey } from 'config/themeConfig'

import Grid from '@mui/material/Grid'

import DataList from 'components/common/DataList'
import { DialogEnum } from 'components/common/Dialog'
import EditButton from 'components/common/EditButton'
import Heading from 'components/common/Heading'
import { FlagName, flag } from 'flag'
import {
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDate,
} from 'services/dateService'

import { Shipment } from 'types/Orders'
import TrackingNumberLink from 'components/common/TrackingNumberLink'
import { useContext } from 'react'
import { OrderShipmentsContext } from './Context'
import BuyShippingLabel from './BuyShippingLabel'
import { regexTimeFormat } from 'v2/constant/regex'

const StyledHeader = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: grey[300],
  padding: theme.spacing(2),
}))

export interface Props {
  canEdit: boolean
  shipment: Shipment
  title: string
}

export const ShipmentCardHeader = ({
  canEdit,
  shipment,
  title = '',
}: Props) => {
  const { orderShipments, reloadShipments } = useContext(OrderShipmentsContext)

  return (
    <Grid container data-testid="shipment-header">
      <Grid item xs={12}>
        <Heading title={title} variant="h3" component="h2">
          <EditButton
            dialogComponent={DialogEnum.EDIT_SHIPMENT}
            componentProps={{ shipment, orderShipments }}
            aria-label={`edit ${title}`}
            hide={!canEdit}
            closeCallback={reloadShipments}
          />
        </Heading>
      </Grid>
      <StyledHeader item xs={12}>
        <DataList
          data={[
            {
              title: 'Tracking Number:',
              element: (
                <TrackingNumberLink
                  trackingNumber={shipment.tracking_number}
                  shippingMethod={shipment.shipping_method}
                />
              ),
            },
            {
              title: 'Shipping Method:',
              element: (
                <>
                  {shipment.shipping_method}
                  {flag(FlagName.BUY_SHIPPING_MARKUP) && (
                    <BuyShippingLabel
                      orderId={shipment.order_id}
                      trackingNumber={shipment.tracking_number}
                    />
                  )}
                </>
              ),
            },
          ]}
        />
        <DataList
          data={[
            {
              title: 'Last modified:',
              value: regexTimeFormat.test(shipment.last_modified)
                ? formatDate(
                    shipment.last_modified,
                    DATE_FORMAT_MONTH_DAY_YEAR_TIME,
                  )
                : 'N/A',
            },
            {
              title: 'Ship date:',
              value: regexTimeFormat.test(shipment.shipped_date)
                ? formatDate(
                    shipment.shipped_date,
                    DATE_FORMAT_MONTH_DAY_YEAR_TIME,
                  )
                : 'N/A',
            },
          ]}
        />
      </StyledHeader>
    </Grid>
  )
}

export default ShipmentCardHeader
