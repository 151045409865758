import styled from '@emotion/styled'
import { Grid } from '@enterprise-ui/canvas-ui-react'

export const StyledSellerPromotionsPage = styled(Grid.Container)`
  .border-all {
    border: 1px solid var(--canvas-border-color--default);
  }

  .border-top {
    border-top: 1px solid var(--canvas-border-color--default);
  }

  .border-bottom {
    border-bottom: 1px solid var(--canvas-border-color--default);
  }

  .border-right {
    border-right: 1px solid var(--canvas-border-color--default);
  }
`
