import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
  Button,
  ButtonGroup,
  Grid,
  Placeholder,
} from '@enterprise-ui/canvas-ui-react'
import {
  CartesianGrid,
  Line,
  LineChart as RechartLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { currentSellerId, currentSellerVmmId } from 'store/selectors'
import { GREEN_FIELD_CARD_ID } from 'v2/constant/greenFieldCardID'
import { useReturnInsightsStore } from 'v2/store'
import { roundToTwoDecimals } from 'v2/utils/helper'
import { ReturnRate } from 'v2/types/returnRateResponse'
import { sellerExecuteCardQuery } from 'v2/query'
import { format } from 'date-fns'
import { datePattern } from 'v2/constant/date'
import { ViewType } from '.'

export type Props = {
  cardId: number
  view: ViewType
  handleViewClick: (view: ViewType) => void
}

export const ReturnRateGraph = ({ cardId, view, handleViewClick }: Props) => {
  const sellerId = useSelector(currentSellerId) ?? ''
  const vmmId = useSelector(currentSellerVmmId) ?? ''
  const { divisionId, categoryId, interval, compareInterval, selectedVmmId } =
    useReturnInsightsStore()

  const [graphData, setGraphData] = useState()

  const { data, isError, isFetching } = useQuery(
    [
      'RETURN_RATE',
      {
        divisionId,
        categoryId,
        interval,
        sellerId,
        vmmId,
        cardId,
        selectedVmmId,
      },
    ],
    () =>
      sellerExecuteCardQuery({
        divisionId,
        categoryId,
        interval,
        sellerId,
        cardId,
        vmmId: selectedVmmId || vmmId,
      }),
  )

  const {
    data: compareData,
    isError: isCompareError,
    isFetching: isCompareFetching,
  } = useQuery(
    [
      'RETURN_RATE',
      {
        divisionId,
        categoryId,
        compareInterval,
        sellerId,
        vmmId,
        cardId,
        selectedVmmId,
      },
    ],
    () =>
      sellerExecuteCardQuery({
        divisionId,
        categoryId,
        interval: compareInterval,
        sellerId,
        cardId,
        vmmId: selectedVmmId || vmmId,
      }),
    {
      enabled: !!compareInterval,
    },
  )

  useEffect(() => {
    if (data) {
      switch (cardId) {
        case GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_DAY: {
          const transformedResponseDay = data.map((res: ReturnRate) => ({
            ...res,
            x_axis: format(new Date(res.x_axis), datePattern.slash.MM_dd_yyyy),
          }))
          return setGraphData(transformedResponseDay)
        }

        case GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_WEEK: {
          const transformedResponseWeek = data.map((res: ReturnRate) => ({
            ...res,
            x_axis: `Wk${res.x_axis}`,
          }))
          return setGraphData(transformedResponseWeek)
        }

        case GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_MONTH: {
          const transformedResponseMonth = data.map((res: ReturnRate) => ({
            ...res,
            x_axis: `M${res.x_axis}`,
          }))
          return setGraphData(transformedResponseMonth)
        }
      }
    }
  }, [data, cardId])

  useEffect(() => {
    if (compareData && data) {
      const mergedData = data.map((item: ReturnRate, idx: number) => {
        return {
          ...item,
          x_axis: format(new Date(item.x_axis), datePattern.slash.MM_dd_yyyy),
          compare_x_axis: format(
            new Date(compareData[idx]?.x_axis),
            datePattern.slash.MM_dd_yyyy,
          ),
          compare_y_axis: compareData[idx]?.y_axis,
        }
      })

      setGraphData(mergedData)
    }
  }, [compareData, data])

  if (isFetching || isCompareFetching) {
    return (
      <Placeholder className="hc-pa-normal">
        <Grid.Container justify="space-between" spacing="dense">
          <Grid.Item xs={12}>
            <Placeholder.Rect height="220px" />
          </Grid.Item>
        </Grid.Container>
      </Placeholder>
    )
  }

  if (isError || isCompareError) {
    return (
      <div className="hc-pa-expanded hc-ta-center">
        <p>There was an error fetching the data</p>
      </div>
    )
  }

  return (
    <Grid.Container direction="column" align="flex-end" justify="flex-end">
      <Grid.Item>
        <ButtonGroup>
          <Button
            aria-label="View Return rate graph Monthly "
            onClick={() => {
              handleViewClick(ViewType.MONTH)
            }}
            type={view === ViewType.MONTH ? 'primary' : 'secondary'}
          >
            Month
          </Button>
          <Button
            aria-label="View Return rate graph Weekly "
            onClick={() => {
              handleViewClick(ViewType.WEEK)
            }}
            type={view === ViewType.WEEK ? 'primary' : 'secondary'}
          >
            Week
          </Button>
          <Button
            aria-label="View Return rate graph Day wise "
            onClick={() => {
              handleViewClick(ViewType.DAY)
            }}
            type={view === ViewType.DAY ? 'primary' : 'secondary'}
          >
            Day
          </Button>
        </ButtonGroup>
      </Grid.Item>
      <Grid.Item xs={12}>
        <ResponsiveContainer className="hc-pb-2x" width="100%" height={250}>
          <RechartLineChart data={graphData} margin={{ bottom: 20 }}>
            <CartesianGrid strokeDasharray="3, 3" />
            <YAxis />
            <XAxis
              dataKey="x_axis"
              angle={30}
              dy={14}
              tick={{ 'aria-hidden': true }}
            />
            <Tooltip
              labelFormatter={(date: string) => {
                return compareInterval ? '' : 'Order Date: ' + date
              }}
              formatter={(value: any, name: string, props: any) =>
                compareInterval
                  ? [
                      `${roundToTwoDecimals(value)}%`,
                      name === 'y_axis'
                        ? props.payload.x_axis
                        : props.payload.compare_x_axis,
                    ]
                  : [`Return Rate: ${roundToTwoDecimals(value)}%`]
              }
            />
            <Line
              type="monotone"
              dataKey="y_axis"
              stroke={'var(--canvas-font-color--interactive)'}
              strokeWidth={3}
            />
            <Line
              type="monotone"
              dataKey="compare_y_axis"
              stroke={'var(--canvas-background-color--red)'}
              strokeWidth={3}
            />
          </RechartLineChart>
        </ResponsiveContainer>
      </Grid.Item>
    </Grid.Container>
  )
}

export default ReturnRateGraph
