import { getPromotionById } from 'v2/services/promotions'

export type PromotionDetails = {
  discount_type: string
  discount_value: number
  location_ids: number[]
}

export type MerchandiseHierarchy = {
  pyramid_id: number
  pyramid_name: string
  division_id?: number
  division_name?: string
}

export interface Promotion {
  promotionId: string
  umbrellaLabel?: string
  details: PromotionDetails[]
  startDate: string
  endDate: string
  merchandiseHierarchies: MerchandiseHierarchy[]
  allowedProductSubTypes?: {
    sub_type_id: string
    sub_type_name: string
    allowed_item_types_ids: string[]
  }[]
  promotionDescription?: string
  participationEndDate?: string
  promotionNotes?: string
}

const promotionByIdQuery = async ({
  promotionId,
  sellerId,
}: {
  promotionId: string
  sellerId: string
}) => {
  const config = {
    params: {
      promotion_id: promotionId,
    },
  }

  const data = await getPromotionById(config, sellerId)
  const promotionData = data?.[0] ?? {}

  return {
    promotionId: promotionData?.promotion_id,
    umbrellaLabel: promotionData?.umbrella_label,
    details: promotionData?.details,
    startDate: promotionData?.start_date,
    endDate: promotionData?.end_date,
    merchandiseHierarchies: promotionData?.merchandise_hierarchies,
    allowedProductSubTypes: promotionData?.allowed_product_sub_types,
    promotionDescription: promotionData?.promotion_description,
    participationEndDate: promotionData?.participation_end_date,
    promotionNotes: promotionData?.promotion_notes,
    fiscalMonth: promotionData?.fiscal_month,
    fiscalWeek: promotionData?.fiscal_week,
  }
}

export default promotionByIdQuery
