import { FC } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import startCase from 'lodash/fp/startCase'
import capitalize from 'lodash/fp/capitalize'
import { Grid, Placeholder, Heading } from '@enterprise-ui/canvas-ui-react'

import HeaderTitle from 'components/common/HeaderTitle'
import { datePattern } from 'v2/constant/date'
import { MerchandiseHierarchy, PromotionDetails } from 'v2/services/promotions'
import { formatDiscountLabel } from 'v2/utils/helper'
import promotionByIdQuery from 'v2/query/promotionByIdQuery'
import { StyledSellerPromotionsPage } from './styles'
import { currentSellerId } from 'store/selectors'

const SellerPromotionDetailsPage: FC = () => {
  const { promotionId = '' } = useParams()
  const sellerId = useSelector(currentSellerId) ?? ''

  const {
    data: promotion,
    isFetching,
    isError,
  } = useQuery(
    ['SELLER_PROMOTION_DETAILS', { promotionId }],
    () =>
      promotionByIdQuery({
        promotionId,
        sellerId,
      }),
    { enabled: !!promotionId },
  )

  if (isFetching) {
    return (
      <>
        <HeaderTitle title="Promotions > Promotions detail" />
        <Placeholder.Rect height="50px" />
        <Placeholder.Rect height="500px" />
      </>
    )
  }

  if (isError) {
    return (
      <div className="hc-pa-expanded hc-ta-center">
        <p>
          There was an error fetching the promotion details. Please try again in
          a few mins.
        </p>
      </div>
    )
  }

  return (
    <StyledSellerPromotionsPage>
      <HeaderTitle title="Promotions > Promotions detail" />

      <Heading className="hc-mb-normal normal-weight " xs={4}>
        {promotion?.promotionDescription ?? promotion?.umbrellaLabel}
      </Heading>

      <Grid.Container className="hc-ma-none hc-bg-grey06 border-all">
        <Grid.Item className="hc-ma-none hc-pa-none border-right" xs={6}>
          <div className="display-flex">
            <p className="hc-mb-none hc-pl-normal hc-pv-dense">
              <strong>Promo ID: </strong>
              {promotion?.promotionId}
            </p>
            {promotion?.fiscalMonth && (
              <p className="hc-mb-none hc-pl-expanded hc-pv-dense">
                <strong>Fiscal Month: </strong>
                {promotion?.fiscalMonth}
              </p>
            )}
            {promotion?.fiscalWeek && (
              <p className="hc-mb-none hc-pl-expanded hc-pv-dense">
                <strong>Fiscal Week: </strong>
                {promotion?.fiscalWeek}
              </p>
            )}
          </div>

          <p className="hc-mb-none border-top border-bottom hc-pl-normal hc-pv-dense">
            <strong>Promo Description: </strong>
            {promotion?.promotionDescription ?? promotion?.umbrellaLabel}
          </p>
          <p className="hc-mb-none border-bottom hc-pl-normal hc-pv-dense">
            <strong>Discount: </strong>
            {promotion?.details?.map((detail: PromotionDetails) => (
              <>
                {startCase(detail?.discount_type)}{' '}
                {formatDiscountLabel(
                  detail?.discount_type,
                  detail?.discount_value,
                )}
              </>
            ))}
          </p>
          {promotion?.merchandiseHierarchies?.length !== 0 && (
            <p className="hc-mb-none border-bottom hc-pl-normal hc-pv-dense">
              <strong>Division: </strong>
              {promotion?.merchandiseHierarchies?.map(
                (merch: MerchandiseHierarchy) => (
                  <>
                    {merch?.division_name &&
                      startCase(merch?.division_name?.toLowerCase())}
                  </>
                ),
              )}
            </p>
          )}

          <p className="hc-mb-none border-bottom hc-pl-normal hc-pv-dense">
            <strong>Start Date: </strong>
            <>
              {promotion?.startDate &&
                format(
                  new Date(promotion?.startDate),
                  datePattern.verbose.MMMM_do_yyyy_time,
                )}
            </>
          </p>
          <p className="hc-mb-none border-bottom hc-pl-normal hc-pv-dense">
            <strong>End Date: </strong>
            <>
              {promotion?.endDate &&
                format(
                  new Date(promotion?.endDate),
                  datePattern.verbose.MMMM_do_yyyy_time,
                )}
            </>
          </p>
          {promotion?.participationEndDate && (
            <p className="hc-mb-none hc-pl-normal hc-pv-dense">
              <strong>Participation Deadline: </strong>
              <>
                {format(
                  new Date(promotion?.participationEndDate),
                  datePattern.verbose.MMMM_do_yyyy,
                )}
              </>
            </p>
          )}
        </Grid.Item>
        <Grid.Item className="hc-pa-none" xs={6}>
          {promotion?.allowedProductSubTypes?.length !== 0 && (
            <p className="hc-mb-none  hc-pl-normal hc-pv-dense">
              <strong>Allowed Subtypes: </strong>
              <>
                {promotion?.allowedProductSubTypes
                  ?.map(
                    (allowed_product_sub_type: any) =>
                      `${capitalize(allowed_product_sub_type?.sub_type_name)} `,
                  )
                  .join(', ')}
              </>
            </p>
          )}

          {promotion?.promotionNotes && (
            <p className="hc-mb-normal">
              <strong>Promo Notes: </strong>
              <>{promotion?.promotionNotes}</>
            </p>
          )}
        </Grid.Item>
      </Grid.Container>
    </StyledSellerPromotionsPage>
  )
}

export default SellerPromotionDetailsPage
