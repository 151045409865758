import { FC, useEffect } from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'

import HeaderTitle from 'components/common/HeaderTitle'
import {
  TopItemType,
  Filter,
  ReturnRate,
  TopSKUType,
} from 'v2/components/feature/ReturnInsights'
import { useReturnInsightsStore } from 'v2/store'

const ReturnInsights: FC = () => {
  const { updateView } = useReturnInsightsStore()

  useEffect(() => {
    updateView('EXTERNAL')
  }, [updateView])

  return (
    <>
      {/* We need to rewrite the HeaderTitle component using React Context. */}
      <HeaderTitle title="Return Insights Dashboard" />
      <Grid.Container className="hc-mb-2x">
        <Grid.Item xs={12}>
          <Filter isExternalView />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container className="hc-mb-2x">
        <Grid.Item xs={6}>
          <ReturnRate />
        </Grid.Item>
        <Grid.Item xs={6}>
          <TopItemType />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container>
        <Grid.Item xs={12}>
          <TopSKUType />
        </Grid.Item>
      </Grid.Container>
    </>
  )
}

export default ReturnInsights
