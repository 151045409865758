export const buildURLQueryParams = (
  params: Record<string, string | boolean | number | null | undefined>,
): string => {
  const searchParams = new URLSearchParams()

  Object.entries(params)
    .filter(
      ([_, value]) =>
        value || typeof value === 'boolean' || typeof value === 'number',
    )
    .forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        searchParams.append(key, String(value))
      }
    })

  return searchParams.toString() ? `${searchParams.toString()}` : ''
}
