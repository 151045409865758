import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'

import Input from 'components/common/Input'
import ValidationErrorText from 'components/common/ValidationErrorText'
import Select from 'components/common/Select'

import { ShippingMethod } from 'types/ShippingMethods'
import { Validation } from 'types/Validation'

const StylesFormHelperText = styled(FormHelperText)`
  color: #000;
  font-size: 16px;
`

export interface Props {
  shippingMethod: Nullable<ShippingMethod>
  method: string
  carrier: string
  mode: string
  routing: string
  scac: string
  isApprovedForPartners: boolean
  csc: string
  validation: Validation
  isValid: boolean
  onRequestCancel: () => void
  onRequestChange: ({
    type,
  }: {
    type: string
  }) => (value: string | undefined) => void
  onRequestSubmit: () => void
}

export const EditShippingMethodsAside = ({
  shippingMethod,
  method,
  carrier,
  mode,
  routing,
  scac,
  csc,
  validation,
  isValid,
  isApprovedForPartners,
  onRequestCancel,
  onRequestChange,
  onRequestSubmit,
}: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              id="method"
              name="method"
              value={method}
              isDisabled={!!(shippingMethod && shippingMethod.id)}
              validation={validation}
              onChange={onRequestChange({ type: 'method' })}
              helperText="Shipping Method *"
              inputProps={{
                maxLength: 30,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              id="carrier"
              name="carrier"
              value={carrier}
              validation={validation}
              onChange={onRequestChange({ type: 'carrier' })}
              helperText="Carrier *"
              inputProps={{
                maxLength: 20,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              id="scac"
              name="scac"
              value={scac}
              validation={validation}
              onChange={onRequestChange({ type: 'scac' })}
              helperText="SCAC *"
              inputProps={{
                maxLength: 20,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              id="csc"
              name="csc"
              value={csc}
              validation={validation}
              onChange={onRequestChange({ type: 'csc' })}
              helperText="CSC *"
              inputProps={{
                maxLength: 20,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              id="routing"
              name="routing"
              value={routing}
              validation={validation}
              onChange={onRequestChange({ type: 'routing' })}
              helperText="Routing *"
              inputProps={{
                maxLength: 20,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              id="mode"
              name="mode"
              onChange={onRequestChange({ type: 'mode' })}
              options={['GROUND', 'AIR']}
              value={mode}
            />
            <StylesFormHelperText>Mode *</StylesFormHelperText>
            {validation && (
              <div className="hc-ml-normal">
                <ValidationErrorText errors={validation} field="mode" />
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormLabel style={{ color: '#000' }}>
              Approved for Partners
            </FormLabel>
            <RadioGroup
              row
              value={isApprovedForPartners}
              onChange={(event) =>
                onRequestChange({ type: 'isApprovedForPartners' })(
                  event.target.value,
                )
              }
            >
              <FormControlLabel value="true" control={<Radio />} label="True" />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="False"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DialogActions>
              <Button
                color="primary"
                onClick={onRequestCancel}
                data-testid="cancel"
              >
                cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onRequestSubmit}
                disabled={!isValid}
                data-testid="submit"
              >
                save
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EditShippingMethodsAside
