import {
  isOneOfUserRoles,
  isUserRoleExternal,
  isUserRoleProductAdmin,
} from './authorization'
import { approvedToListStatuses } from './seller'

import {
  USER_ROLE_ADMIN,
  USER_ROLE_OPS,
  USER_ROLE_APP_SMS_ADMIN,
  USER_ROLE_ITEM_MANAGER,
  USER_ROLE_ITEM_APPROVER,
  USER_ROLE_PROGRAM_MANAGER,
  USER_ROLE_COMMUNICATIONS,
  USER_ROLE_REPORTER,
} from './roles'

import { Source, SellerStatus } from 'types/Seller'
import apiConfig, { ENVIRONMENT_PRODUCTION } from 'config/apiConfig'
import { flag, FlagName } from 'flag'

export const hasErrorConfigPage = (memberOf: string[]) =>
  !isUserRoleExternal(memberOf)

export const hasHomePage = (memberOf: string[]) => !isUserRoleExternal(memberOf)

export const hasItemUploadPage = (
  memberOf: string[],
  source?: string,
  status?: SellerStatus,
) =>
  isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_OPS,
    USER_ROLE_APP_SMS_ADMIN,
    USER_ROLE_ITEM_MANAGER,
    USER_ROLE_ITEM_APPROVER,
  ]) &&
  (source === Source.DIRECT_FROM_SELLER || source === Source.COMMERCEHUB) &&
  status &&
  [...approvedToListStatuses, SellerStatus.PARTNER_SETUP].includes(status)

export const hasSalesMetricsPage = (memberOf: string[]) =>
  !isUserRoleExternal(memberOf) ||
  isOneOfUserRoles(memberOf, [USER_ROLE_APP_SMS_ADMIN])

export const hasFeedbackPage = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN]) || isUserRoleExternal(memberOf)

export const hasReturnDisputeCasesPage = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_OPS])

export const hasPromotions = (memberOf: string[]) =>
  (isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_PROGRAM_MANAGER,
    USER_ROLE_REPORTER,
  ]) &&
    apiConfig.environment === ENVIRONMENT_PRODUCTION) ||
  apiConfig.environment !== ENVIRONMENT_PRODUCTION

export const hasItemProcessingPage = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_ITEM_MANAGER,
    USER_ROLE_ITEM_APPROVER,
  ])

export const hasItemVersionPage = (memberOf: string[]) =>
  isUserRoleProductAdmin(memberOf)

export const hasKafkaTopicsSearchPage = (memberOf: string[]) =>
  isUserRoleProductAdmin(memberOf)

export const hasUtilitiesNav = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_PROGRAM_MANAGER,
    USER_ROLE_ITEM_MANAGER,
    USER_ROLE_COMMUNICATIONS,
    USER_ROLE_OPS,
  ])

export const hasBarcodeReleasePage = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_PROGRAM_MANAGER,
    USER_ROLE_ITEM_MANAGER,
  ])

export const hasUnlistItemsPageAccess = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [
    USER_ROLE_OPS,
    USER_ROLE_ADMIN,
    USER_ROLE_PROGRAM_MANAGER,
  ])

export const hasPhoneEditPage = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_OPS])

export const hasAlertManagementPage = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_COMMUNICATIONS])

export const hasTimeline = (memberOf: string[]) =>
  isUserRoleProductAdmin(memberOf)

export const hasReturnShippingRatesNav = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_OPS])

export const hasHolidayClosuresPage = (memberOf: string[]) =>
  !isUserRoleExternal(memberOf)

export const hasOnboardingPage = (memberOf: string[]) =>
  !isUserRoleExternal(memberOf)

export const hasShippingMarkupPage = (memberOf: string[]) =>
  flag(FlagName.BUY_SHIPPING_MARKUP) && !isUserRoleExternal(memberOf)

export const hasReturnsResearchPage = (memberOf: string[]) =>
  !isUserRoleExternal(memberOf)

export const hasReturnDashboardInternalAccess = () =>
  flag(FlagName.RETURN_INSIGHT_DASHBOARD_INTERNAL)

export const hasReturnDashboardExternalAccess = () =>
  flag(FlagName.RETURN_INSIGHT_DASHBOARD_EXTERNAL)

export const hasTgidConsentPageAccess = (memberOf: string[]) =>
  flag(FlagName.TGID_CONSENT) &&
  (!isUserRoleExternal(memberOf) ||
    isOneOfUserRoles(memberOf, [USER_ROLE_APP_SMS_ADMIN]))

export const hasPromotionDetailsPageAccess = () =>
  flag(FlagName.PROMO_VISIBILITY)
