import axios from 'axios'

import { url } from 'v2/utils'
import apiConfig from 'config/apiConfig'

interface SellerSearchParams {
  q?: string | undefined
  vmm_id?: string | undefined
  legal_business_name?: string | undefined
  source?: string | undefined
  ship_node_id?: string | undefined
}

export interface SellerSearchType {
  id: string
  vmm_id: number
  display_name: string
}

export async function getSellerSearch(
  params: SellerSearchParams,
): Promise<SellerSearchType[]> {
  const { q, legal_business_name, ship_node_id, source, vmm_id } = params
  const queryParams = url.buildURLQueryParams({
    q,
    legal_business_name,
    ship_node_id,
    source,
    vmm_id,
  })

  try {
    const { data } = await axios.get(
      `${apiConfig.sms}/sellers_search?${queryParams}`,
    )

    return data
  } catch (error) {
    return Promise.reject([])
  }
}
