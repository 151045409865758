import { FC, useEffect } from 'react'
import { Heading, Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'

import {
  useAsyncSellerSelect,
  useGreenFieldDivisionAutoComplete,
  useGreenFieldCategoryAutoComplete,
} from 'v2/hooks/autoComplete'
import { useReturnInsightsStore } from 'v2/store'
import { StyledFillterContainer, StyledDatePicker } from '../styles'
import Download from './Download'
import HelpArticle from './HelpArticle'

interface OwnProps {
  isExternalView?: boolean
}

const ReturnInsightsFilter: FC<OwnProps> = ({ isExternalView = false }) => {
  const { onDivisionUpdate, divisionOptions, selectedDivisionOption } =
    useGreenFieldDivisionAutoComplete()
  const { renderComponent, selectedOptions } = useAsyncSellerSelect()
  const { categoryOptions, onCategoryUpdate, selectedCategoryOption } =
    useGreenFieldCategoryAutoComplete()
  const {
    updateDivisionId,
    updateTimeFrame,
    updateCategoryId,
    timeFrame,
    dateQuickSelectionRange,
    datePickerDisableDate,
    updateVmmId,
    updateSellerId,
  } = useReturnInsightsStore()

  useEffect(() => {
    updateDivisionId(selectedDivisionOption?.id ?? null)
  }, [updateDivisionId, selectedDivisionOption?.id])

  useEffect(() => {
    updateCategoryId(selectedCategoryOption?.id ?? null)
  }, [updateCategoryId, selectedCategoryOption?.id])

  useEffect(() => {
    updateVmmId(selectedOptions?.vmmId?.toString() ?? '')
    updateSellerId(selectedOptions?.id ?? '')
  }, [selectedOptions, updateVmmId, updateSellerId])

  const onDatePickerUpdate = (_id: string, value: any) => {
    if (value.startDate && value.endDate) {
      updateTimeFrame(value)
    }
  }

  return (
    <StyledFillterContainer className="hc-bg-grey07 hc-pa-normal">
      <Grid.Container justify="space-between">
        <Grid.Item>
          <Heading size={4}>Showing Details For :</Heading>
        </Grid.Item>
        <Grid.Item>
          <Grid.Container>
            {isExternalView && (
              <Grid.Item>
                <HelpArticle />
              </Grid.Item>
            )}
            <Grid.Item>
              <Download />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
      <Grid.Container>
        {!isExternalView && (
          <Grid.Item xs={3}>
            <label>
              <strong>Partner</strong>
            </label>
            {renderComponent({ placeholder: 'Select Partner' })}
          </Grid.Item>
        )}
        <Grid.Item xs={isExternalView ? 4 : 3}>
          <Autocomplete
            label="Category"
            placeholder="Select Category"
            options={categoryOptions}
            onUpdate={onCategoryUpdate}
          />
        </Grid.Item>
        <Grid.Item xs={isExternalView ? 4 : 3}>
          <Autocomplete
            label="Division"
            placeholder="Select Division"
            options={divisionOptions}
            onUpdate={onDivisionUpdate}
          />
        </Grid.Item>
        <Grid.Item xs={isExternalView ? 4 : 3}>
          <StyledDatePicker
            id="return-insights-date-picker"
            type="gregorian-range"
            label="Order Time Frame"
            onUpdate={onDatePickerUpdate}
            value={timeFrame}
            quickSelectRange={dateQuickSelectionRange}
            disableDates={{ after: datePickerDisableDate }}
            hintText="Returns data cannot be accessed for recent 30 days"
          />
        </Grid.Item>
      </Grid.Container>
    </StyledFillterContainer>
  )
}

export default ReturnInsightsFilter
